import React from 'react';
import { MaybeImage } from '../Image';

export interface CardBase {
  id?: string | number;
  description?: string | null;
  href?: string | null;
  image?: MaybeImage | null;
  meta?: string | (string | undefined | null)[];
  email?: string | null;
  phone?: string | null;
  title?: string | null;
  honorific?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  titleComponent?: React.ElementType;
  customText?: string | null;
  disabled?: boolean;
}

const cardDefaultState: CardBase = {
  href: '',
  titleComponent: 'h2',
};

/**
 * Create the {@link React.Context}, related Provider and consumer hook for Card component
 */
export const createCardContext = <T extends CardBase>(defaultState: T) => {
  const CardContext = React.createContext<T>(defaultState);
  const CardProvider = CardContext.Provider;
  const useCard = () => React.useContext(CardContext);
  return {
    CardContext,
    CardProvider,
    useCard,
  };
};

export const { CardProvider, useCard } = createCardContext(cardDefaultState);
