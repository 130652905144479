import { gql } from '__generated__';

export const SIBLING_BRANCH_FRAGMENT = gql(`
  fragment siblingBranch on EntryInterface {
    children {
      title
      uri
      id
      level
      children {
        title
        uri
        id
        level
        children {
          title
          uri
          id
          level
        }
    }
    }
  }
`);

export const ENTRY_ID_FRAGMENT = gql(`
  fragment entryId on EntryInterface {
    __typename
    id
    title
    parent {
      id
      title
    }
  }
`);

export const ENTRY_BASE_FRAGMENT = gql(`
  fragment entryBase on EntryInterface {
    __typename
    typeHandle
    searchScore
    id
    uri
    url
    slug
    title
    sectionHandle
    status
    enabled
    postDate @formatDateTime(format: "j M, Y")
    parent {
      id
      uri
      title
    }
  }
`);

export const ARTICLE_INDEX_ENTRY_FRAGMENT = gql(`
  fragment articleIndexEntry on index_Entry {
    ...articleIndexCard
    articleSingle {
      ...articleCard
    }
    articleMultiple {
      ...articleCard
    }
  }
`);

export const ARTICLE_ENTRY_FRAGMENT = gql(`
  fragment articleEntry on article_Entry {
    ...articleCard
    blocks {
      ...blocks
    }
    eventMultiple {
      ...eventCard
    }
    articleMultiple {
      ...articleCard
    }
  }
`);

export const HOME_ENTRY_FRAGMENT = gql(`
  fragment homeEntry on home_Entry {
    ...homeCard
    blocks {
      ...blocks
    }
  }
`);

export const VENUE_ENTRY_FRAGMENT = gql(`
  fragment venueEntry on venue_Entry {
    ...venueCard
    blocks {
      ...blocks
    }
    venueCategory {
      ...categories
    }
  }
`);

export const PAGE_STANDARD_ENTRY_FRAGMENT = gql(`
  fragment pageStandardEntry on standard_Entry {
    ...pageStandardCard
    typeHandle
    siblingBranch: parent {
      ...siblingBranch
    }
    ancestors {
      id
      title
      uri
      parent {
        id
      }
    }
    blocks {
      ...blocks
    }
  }
`);

export const PAGE_OVERVIEW_ENTRY_FRAGMENT = gql(`
  fragment pageOverviewEntry on overview_Entry {
    ...pageOverviewCard
    blocks {
      ...blocks
    }
  }
`);

export const PAGE_EXPANDABLE_ENTRY_FRAGMENT = gql(`
  fragment pageExpandableEntry on expandable_Entry {
    ...pageExpandableCard
    typeHandle
    blocks {
      ...blocks
    }
  }
`);

export const SHORTLIST_ENTRY_FRAGMENT = gql(`
  fragment shortlistEntry on index_Entry {
    ...shortlistCard
  }
`);

export const SEARCH_ENTRY_FRAGMENT = gql(`
  fragment searchEntry on index_Entry {
    ...searchCard
  }
`);

export const NOTFOUND_ENTRY_FRAGMENT = gql(`
  fragment notFoundEntry on notFound_Entry {
    ...notFoundCard
  }
`);

export const EVENT_INDEX_ENTRY_FRAGMENT = gql(`
  fragment eventIndexEntry on index_Entry {
    ...eventIndexCard
  }
`);

export const EVENT_ENTRY_FRAGMENT = gql(`
  fragment eventEntry on event_Entry {
    ...eventCard
    status
    blocks {
      ...blocks
    }
    onSale
    programCategory {
      ...categories
    }
    accessibilityCategory {
      ...categories
    }
    eventLabelCategory {
      ...eventLabelCategory
    }
    eventTypeCategory {
      ...categories
    }
    eventPrice {
      ...eventPriceCard
    }
    eventTime {
      ...eventTimeCard
    }
    eventId
    venueCategory {
      ...categories
    }
    eventInfo {
      ...eventInfoCard
    }
    faqMultiple {
      ...faqCard
    }
  }
`);

export const FAQ_INDEX_ENTRY_FRAGMENT = gql(`
  fragment faqIndexEntry on faqIndex_Entry {
    ...faqIndexCard
  }
`);

export const EVENT_INFO_ENTRY = gql(`
  fragment eventInfoEntry on eventInfo_Entry {
    ...eventInfoCard
  }
`);

export const EVENT_PRICE_ENTRY = gql(`
  fragment eventPriceEntry on eventPrice_Entry {
    ...eventPriceCard
  }
`);

export const EVENT_TIME_ENTRY = gql(`
  fragment eventTimeEntry on eventTime_Entry {
    ...eventTimeCard
  }
`);

//  -------------------------------------------------------------------------------------------------
//  ---- Put it all together ----

export const ENTRIES_FRAGMENT = gql(`
  fragment entries on EntryInterface {
    ...entryBase
    ...entrySeo
    ...articleIndexEntry
    ...articleEntry
    ...homeEntry
    ...venueEntry
    ...pageStandardEntry
    ...pageOverviewEntry
    ...pageExpandableEntry
    ...shortlistEntry
    ...searchEntry
    ...notFoundEntry

    ...eventIndexEntry
    ...eventEntry
    
    ...faqIndexEntry
    # ...faqEntry

    # Event matrix entries
    # ...eventInfoEntry
    # ...eventPriceEntry
    # ...eventTimeEntry
  }
`);
