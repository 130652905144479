import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
    <path
      fill="currentColor"
      d="M22.616 14.306H20.5a.329.329 0 0 0-.328.33v4.583H5.718v-4.583a.33.33 0 0 0-.329-.33H3.274a.33.33 0 0 0-.329.33v7.034c0 .183.149.33.329.33h19.342a.329.329 0 0 0 .329-.33v-7.034a.33.33 0 0 0-.329-.33Z"
    />
    <path
      fill="currentColor"
      d="M17.176 10.568H14.32v-8.21a.358.358 0 0 0-.357-.357h-2.034a.357.357 0 0 0-.357.357v8.21H8.714a.496.496 0 0 0-.494.564.499.499 0 0 0 .114.256l4.23 5.05c.2.237.564.237.762 0l4.23-5.05a.498.498 0 0 0-.38-.82Z"
    />
  </svg>
);
export default SvgComponent;
