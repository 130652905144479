import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import useShortlistStore from '@/lib/store/shortlist';
import { createFragmentParser } from '@liquorice/gql-utils';
import { mergePropsClassName } from '@liquorice/utils';
import IconButton, { IconButtonProps } from '../IconButton';
import * as styles from './Card.css';

export type CardHeartProps = Partial<IconButtonProps>;

export const CardHeart = (props: CardHeartProps) => {
  const events = useShortlistStore((s) => s.events);

  const getId = createFragmentParser(ENTRY_BASE_FRAGMENT, (data) => data?.id);
  const eventId = createFragmentParser(EVENT_CARD_FRAGMENT, (data) => getId(data));

  const isShortlisted = events?.some((e) => eventId(e) === props.itemID);

  return (
    <IconButton
      icon={isShortlisted ? 'heart' : 'heartOutlined'}
      variant="text"
      color="white"
      {...mergePropsClassName(props, styles.heart)}
    />
  );
};
