import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
    <path
      fill="currentColor"
      d="M22.62 12.325h-2.116a.327.327 0 0 0-.332.33v6.609H5.724V4.825h6.358a.33.33 0 0 0 .33-.33v-2.12a.33.33 0 0 0-.328-.331H3.276a.331.331 0 0 0-.331.332v19.339c0 .181.149.329.331.329H22.62a.329.329 0 0 0 .329-.33v-9.06a.33.33 0 0 0-.329-.329Z"
    />
    <path
      fill="currentColor"
      d="m17.562 5.442-5.791 5.793a.357.357 0 0 0 0 .505l1.44 1.438a.356.356 0 0 0 .504 0l5.791-5.793 2.022 2.02c.297.3.81.115.846-.307l.234-2.683.337-3.871a.497.497 0 0 0-.539-.542l-3.871.338-2.681.234a.497.497 0 0 0-.309.847l2.017 2.021Z"
    />
  </svg>
);
export default SvgComponent;
