import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m6.69 4 3.104-3L25.31 16 9.794 31 6.69 28l12.316-12L6.69 4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
