import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m28 6.69 3 3.104L16 25.31 1 9.794 4 6.69l12 12.316L28 6.69Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
