'use client';

import { SvgIconProps, SvgIconTypeMap } from '@/components/ui';
import { SprinklesProps, useSprinklesProps } from '@/theme';

import Error from '@mui/icons-material/Error';
import Facebook from '@mui/icons-material/Facebook';
import Heart from '@mui/icons-material/Favorite';
import HeartOutlined from '@mui/icons-material/FavoriteBorder';
import Instagram from '@mui/icons-material/Instagram';
import Menu from '@mui/icons-material/Menu';
import Twitter from '@mui/icons-material/Twitter';

import { SvgIcon } from '@mui/material';
import * as style from './Icon.css';

import Alert from './icons/Alert';
import AllEvents from './icons/AllEvents';
import Calendar from './icons/Calendar';
import Cart from './icons/Cart';
import Check from './icons/Check';
import ChevronDown from './icons/ChevronDown';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';
import ChevronUp from './icons/ChevronUp';
import Close from './icons/Close';
import Download from './icons/Download';
import External from './icons/External';
import Home from './icons/Home';
import IndexIcon from './icons/IndexIcon';
import Link from './icons/Link';
import Logo from './icons/Logo';
import Minus from './icons/Minus';
import Plus from './icons/Plus';
import Search from './icons/Search';
import Ticket from './icons/Ticket';
import Upload from './icons/Upload';
import User from './icons/User';

// import Star from './icons/StarFull';
// import StartOutlined from './icons/StarHalf';

export const appIcons = {
  menu: Menu,
  error: Error,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  heart: Heart,
  heartOutlined: HeartOutlined,
} as const;

export const customIcons = {
  indexIcon: IndexIcon,
  logo: Logo,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  home: Home,
  download: Download,
  close: Close,
  shoppingCart: Cart,
  calendar: Calendar,
  link: Link,
  check: Check,
  search: Search,
  plus: Plus,
  minus: Minus,
  ticket: Ticket,
  upload: Upload,
  profile: User,
  alert: Alert,
  allEvents: AllEvents,
  external: External,
};

export const icons = { ...appIcons, ...customIcons } as const;

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;

export type CustomIcons = typeof customIcons;
export type CustomIcon = CustomIcons[CustomIconName];
export type CustomIconName = keyof CustomIcons;

export type Icons = typeof icons;
export type Icon = Icons[IconNames];
export type IconNames = AppIconName | CustomIconName;
export type { IconNames as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: IconNames;
    className?: string;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, ...rest }: IconProps) => {
  const Component = icons[name];
  const props = useSprinklesProps(rest);

  const sx = {
    fontSize: style.iconVars.fontSize,
    ...props.sx,
  };

  // If name equals the key of any of the customIcons, render the custom icon
  if (name in customIcons)
    return (
      <SvgIcon
        fontSize="inherit"
        // Causes issues with some svgs
        // inheritViewBox={true}
        viewBox="0 0 32 32"
        {...props}
        sx={sx}>
        <Component />
      </SvgIcon>
    );

  return <Component fontSize="inherit" {...props} sx={sx} />;
};

export default Icon;
