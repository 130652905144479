import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.47529 3.34151C8.99926 2.45081 10.7352 1.98747 12.5003 2.00026C14.314 2.00026 15.9878 2.44776 17.5203 3.34151C19.0312 4.21455 20.286 5.46939 21.159 6.98026C22.0505 8.50209 22.5139 10.2366 22.5003 12.0003C22.5003 13.814 22.0528 15.4878 21.159 17.0203C20.286 18.5311 19.0312 19.786 17.5203 20.659C15.9985 21.5504 14.2639 22.0139 12.5003 22.0003C10.7366 22.0139 9.00212 21.5504 7.48029 20.659C5.96943 19.786 4.71459 18.5311 3.84154 17.0203C2.9501 15.4984 2.48667 13.7639 2.50029 12.0003C2.50029 10.1865 2.94779 8.51276 3.84154 6.98026C4.71342 5.47062 5.96715 4.21598 7.47529 3.34151ZM14.1653 18.2378V15.7628C14.1684 15.6497 14.1266 15.54 14.049 15.4578C14.0128 15.4177 13.9684 15.3858 13.9188 15.3642C13.8692 15.3427 13.8156 15.3319 13.7615 15.3328H11.2615C11.2052 15.333 11.1496 15.3448 11.098 15.3673C11.0464 15.3899 10.9999 15.4228 10.9615 15.464C10.9208 15.5026 10.8884 15.5491 10.8662 15.6007C10.8441 15.6523 10.8327 15.7079 10.8328 15.764V18.2378C10.8328 18.3503 10.8753 18.4503 10.9628 18.5378C11.0503 18.624 11.1503 18.6665 11.2628 18.6665H13.7628C13.8753 18.6665 13.9703 18.6253 14.049 18.5428C14.1266 18.4605 14.1684 18.3508 14.1653 18.2378ZM14.1403 13.7578L14.3753 5.67276C14.3775 5.62539 14.3665 5.57835 14.3435 5.53686C14.3206 5.49537 14.2866 5.46107 14.2453 5.43776C14.1564 5.36779 14.0459 5.33109 13.9328 5.33401H11.0678C10.9547 5.33109 10.8442 5.36779 10.7553 5.43776C10.714 5.46107 10.68 5.49537 10.657 5.53686C10.6341 5.57835 10.6231 5.62539 10.6253 5.67276L10.8465 13.7578C10.8465 13.8453 10.8903 13.9203 10.9765 13.9865C11.064 14.0515 11.1678 14.084 11.289 14.084H13.6978C13.8081 14.0878 13.9162 14.0533 14.004 13.9865C14.0417 13.9601 14.0734 13.9261 14.0971 13.8867C14.1207 13.8473 14.1346 13.8034 14.1403 13.7578Z"
      fill="#E20000"
    />
  </svg>
);
export default SvgComponent;
