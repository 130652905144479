import { gql } from '__generated__';

export const SOCIAL_LINKS_FRAGMENT = gql(`
  fragment socialLinks on socialLinks_Entry {
    __typename
    facebook
    twitter
    instagram
  }
`);

export const ADDRESS_BASE_FRAGMENT = gql(`
  fragment address on Address {
    __typename
    addressLine1
    addressLine2
    addressLine3
    administrativeArea
    countryCode
    organization
    organizationTaxId
    postalCode
    sortingCode
    latitude
    locality
    longitude
    language
    dependentLocality
  }
`);

export const COMMON_LINK_FRAGMENT = gql(`
  fragment commonLink on EntryInterface {
    id
    uri
    title
  }
`);

export const COMMON_LINK_QUERY = gql(`
    query commonLinks($site: [String] = "default") {
      home: entry(site: $site, uri: "__home__") {
        ...commonLink
      }
      search: entry(site: $site, section: "search") {
        ...commonLink
      }
      articleIndex: entry(site: $site, section: "articleIndex") {
        ...commonLink
      }
      eventIndex: entry(site: $site, section: "page", type: "index") {
        ...commonLink
      }
  }
`);
