import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.6 6.768 3.636 3.75-6.583 6.79-3.637-3.751 6.584-6.79ZM11.169 18.39l7.11-7.335a.742.742 0 0 0 .22-.534.741.741 0 0 0-.22-.535L14.117 5.69a.691.691 0 0 0-.52-.213.69.69 0 0 0-.518.213L5.97 13.026a.742.742 0 0 0-.218.533c0 .205.073.383.218.534l4.165 4.297a.689.689 0 0 0 .519.214.691.691 0 0 0 .516-.214Zm10.23-7.56L10.961 21.606a1.393 1.393 0 0 1-1.042.44 1.394 1.394 0 0 1-1.042-.44L7.43 20.111c.43-.443.644-.981.644-1.614 0-.633-.215-1.171-.644-1.615a2.097 2.097 0 0 0-1.564-.663 2.11 2.11 0 0 0-1.567.663l-1.436-1.494a1.485 1.485 0 0 1-.426-1.077c0-.423.142-.781.426-1.072L13.298 2.482a1.395 1.395 0 0 1 1.044-.438c.41 0 .758.146 1.042.44l1.438 1.484c-.43.442-.644.982-.644 1.613 0 .634.215 1.172.644 1.614.43.444.953.666 1.565.666a2.09 2.09 0 0 0 1.564-.666L21.4 8.68a1.485 1.485 0 0 1 .428 1.074c0 .424-.144.782-.427 1.073l-.004.004Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
