import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardEmailProps<D extends React.ElementType = 'p'> = TxtProps<D>;

export const CardEmail = <D extends React.ElementType>({
  children,
  ...props
}: CardEmailProps<D>) => {
  const { firstName } = useCard();
  const content = children ?? firstName;

  return (
    <Txt as="span" color="neutralDark" {...mergePropsClassName(props, style.email)}>
      Email {content}
    </Txt>
  );
};
