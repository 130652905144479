import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
    <path
      fill="currentColor"
      d="M22.5 14.349h-2.116a.328.328 0 0 0-.329.33v4.583H5.602V14.68a.33.33 0 0 0-.328-.331H3.158a.33.33 0 0 0-.329.33v7.034c0 .184.149.331.329.331H22.5a.328.328 0 0 0 .328-.33V14.68a.328.328 0 0 0-.328-.331Z"
    />
    <path
      fill="currentColor"
      d="M17.06 8.091h-2.856v8.21a.358.358 0 0 1-.357.357h-2.035a.357.357 0 0 1-.357-.358V8.091H8.598a.496.496 0 0 1-.492-.564.499.499 0 0 1 .112-.255l4.23-5.05a.498.498 0 0 1 .763 0l4.23 5.05a.498.498 0 0 1-.38.82Z"
    />
  </svg>
);
export default SvgComponent;
