import { mergePropsClassName } from '@liquorice/utils';
import * as style from './Card.css';
import { CardDescription } from './Card.Description';
import { CardMeta } from './Card.Meta';
import { CardTitle } from './Card.Title';
import Box, { BoxProps } from '../ui/Box';

export type CardBodyProps = BoxProps<'div'>;

export const CardBody = ({ children, ...props }: CardBodyProps) => {
  return (
    <Box {...props} {...mergePropsClassName(props, style.body)}>
      {children || (
        <>
          <CardTitle />
          <CardMeta />
          <CardDescription />
        </>
      )}
    </Box>
  );
};
