'use client';

import { CommonLinks } from '@/lib/getters/getCommonLinks';
import { EntriesFragment } from '__generated__/graphql';
import { createContext, ReactNode } from 'react';

export type Anchor = {
  id: string;
  heading: string;
  href: string;
};

export type AppContext = {
  data: EntriesFragment | null;
  commonLinks: CommonLinks | null;
};

const initialState: AppContext = {
  data: null,
  commonLinks: null,
};

export const appContext = createContext<AppContext | undefined>(undefined);

export const EntryProvider = ({
  children,
  value: inheritedValue,
}: {
  children: ReactNode;
  value: Partial<AppContext>;
}) => {
  const value: AppContext = {
    ...initialState,
    ...inheritedValue,
  };

  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};
export default EntryProvider;
