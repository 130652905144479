import Txt, { TxtProps } from '../ui/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.css';
import { isArray } from 'lodash';

export type CardPhoneProps<D extends React.ElementType = 'p'> = TxtProps<D>;

export const CardPhone = <D extends React.ElementType>({
  children,
  ...props
}: CardPhoneProps<D>) => {
  const { phone } = useCard();
  const content = children ?? phone;

  const hasContent = !!content && (!isArray(content) || content.length);

  return hasContent ? (
    <Txt as="span" color="neutralDark" {...mergePropsClassName(props, style.phone)}>
      {content}
    </Txt>
  ) : null;
};
