import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
    <path
      fill="currentColor"
      d="M10.672 15.759a5.542 5.542 0 1 1 0-11.084 5.542 5.542 0 0 1 0 11.084Zm11.576 4.105-4.914-4.915a8.173 8.173 0 1 0-6.662 3.44 8.131 8.131 0 0 0 4.715-1.5l4.916 4.92a.392.392 0 0 0 .554 0l1.391-1.393a.391.391 0 0 0 0-.552Z"
    />
  </svg>
);
export default SvgComponent;
