import { EVENT_TIME_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { toNumber, toString, toStringOrNull } from '@liquorice/utils';
import { EventTimeCardFragment } from '__generated__/graphql';

export type EventTime = {
  ownerId?: number;
  date?: string;
  eventTimeId?: string | null;
};

export const parseEventTime = (data: Maybe<EventTimeCardFragment>): EventTime | null => {
  if (!data) return null;

  const { date, ownerId, eventTimeId } = data;

  return {
    ownerId: toNumber(ownerId),
    date: toString(date),
    eventTimeId: toStringOrNull(eventTimeId),
  };
};

export const parseEventTimeFragment = createFragmentArrayParser(
  EVENT_TIME_CARD_FRAGMENT,
  (items) => {
    return items.map((item) => parseEventTime(item));
  }
);
