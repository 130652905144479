import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m25.31 28-3.104 3L6.69 16 22.206 1l3.104 3-12.316 12L25.31 28Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
