import { gql } from '__generated__';

export const CATEGORY_ID_FRAGMENT = gql(`
  fragment categoryId on CategoryInterface {
    __typename
    id
    title
    parent {
      id
    }
  }
`);

export const ACCESSIBILITY_CATEGORY_FRAGMENT = gql(`
  fragment accessibilityCategory on accessibilityCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
  }
`);

export const EVENT_TYPE_CATEGORY_FRAGMENT = gql(`
  fragment eventTypeCategory on eventTypeCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
  }
`);

export const GENRE_CATEGORY_FRAGMENT = gql(`
  fragment genreCategory on genreCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
    imageSingle {
      ...image
    }
  }
`);

export const VENUE_CATEGORY_FRAGMENT = gql(`
  fragment venueCategory on venueCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
    address {
      ...address
    }
    venueSingle {
      ...venueCard
    }
    venueShorthand
    bookingUrl: externalUrl
    capacity: htmlContentSimple
    venueInfo {
      ...eventInfoCard
    }
  }
`);

export const PROGRAM_CATEGORY_FRAGMENT = gql(`
  fragment programCategory on programCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
    colorScheme {
      label
    }
  }
`);

export const FAQ_CATEGORY_FRAGMENT = gql(`
  fragment faqCategory on faqCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
  }
`);

export const EVENT_LABEL_CATEGORY_FRAGMENT = gql(`
  fragment eventLabelCategory on eventLabelCategory_Category {
    __typename
    id
    uri
    slug
    groupHandle
    title
  }
`);

export const CATEGORIES_FRAGMENT = gql(`
  fragment categories on CategoryInterface {
    ...accessibilityCategory
    ...eventTypeCategory
    ...genreCategory
    ...venueCategory
    ...programCategory
    ...faqCategory
    ...eventLabelCategory
  }
`);
