import Image, { ImageOwnProps } from '@/components/Image';
import { mergePropsClassName } from '@liquorice/utils';
import NextAnchor from '../ui/NextAnchor';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardImageProps = ImageOwnProps & {
  enableLinkWrap?: boolean;
};

export const CardImage = ({ ShimProps, enableLinkWrap = false, ...props }: CardImageProps) => {
  const { title, image, href, disabled } = useCard();

  const inner = (
    <Image
      data={image}
      alt={title ?? ''}
      aria-label={undefined}
      {...mergePropsClassName(props, style.image)}
      ShimProps={mergePropsClassName(ShimProps, style.imageWrap({ disabled }))}
    />
  );

  if (enableLinkWrap && href) {
    return <NextAnchor href={href}>{inner}</NextAnchor>;
  }

  return inner;
};
