import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m4 25.31-3-3.104L16 6.69l15 15.516-3 3.104-12-12.316L4 25.31Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
