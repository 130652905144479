import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
    <path
      fill="#3D854C"
      fillRule="evenodd"
      d="M5.401 4.947C7.366 2.983 9.733 2 12.5 2c2.768 0 5.126.975 7.075 2.925C21.525 6.873 22.5 9.232 22.5 12c0 2.768-.975 5.126-2.925 7.075C17.628 21.026 15.269 22 12.5 22s-5.126-.974-7.075-2.924C3.475 17.128 2.5 14.769 2.5 12c0-2.77.967-5.12 2.901-7.054Zm12.056 3.55c.03-.033.043-.079.043-.141 0-.094-.014-.155-.043-.188L16.12 7.047c-.026-.031-.084-.046-.17-.046-.057 0-.1.015-.13.046l-4.785 6.682c-.648-.668-1.294-1.338-1.94-2.008-.088-.094-.173-.14-.259-.14-.029 0-.086.046-.172.14l-1.078 1.167-.043.047c-.03.032-.043.093-.043.188 0 .061.014.107.043.139l.087.047 3.405 3.55c.086.095.158.141.215.141.057 0 .129-.046.215-.14l5.992-8.363Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
