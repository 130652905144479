import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.817 17.896-1 1.024a2.204 2.204 0 0 1-1.585.635 2.201 2.201 0 0 1-2.244-2.22c0-.294.057-.574.17-.842.115-.27.28-.514.488-.72l3.707-3.684c.39-.374.903-.74 1.537-1.098.634-.358 1.22-.268 1.756.268.244.227.537.342.878.342a1.12 1.12 0 0 0 .853-.367 1.198 1.198 0 0 0 .367-.878 1.2 1.2 0 0 0-.367-.878c-.894-.878-1.955-1.245-3.182-1.098-1.228.146-2.419.797-3.574 1.952l-3.73 3.684c-.433.446-.78.967-1.026 1.537a4.478 4.478 0 0 0-.365 1.782c0 .635.122 1.236.365 1.805a5.09 5.09 0 0 0 1.025 1.538c.443.447.975.796 1.562 1.024 1.139.456 2.41.456 3.548 0a4.516 4.516 0 0 0 1.55-1.024l1.023-1.025a1.122 1.122 0 0 0 .365-.854 1.199 1.199 0 0 0-.365-.878 1.198 1.198 0 0 0-.878-.367 1.242 1.242 0 0 0-.878.342ZM21.11 3.55c-.978-.962-2.065-1.462-3.269-1.504-1.203-.038-2.252.38-3.146 1.26l-1.268 1.268a1.12 1.12 0 0 0-.367.853 1.198 1.198 0 0 0 .367.878 1.197 1.197 0 0 0 .878.367c.326.006.641-.117.878-.342l1.243-1.268c.473-.456.98-.627 1.525-.513a2.73 2.73 0 0 1 1.403.756 2.197 2.197 0 0 1 .658 1.585c0 .294-.057.574-.17.843-.115.269-.28.513-.488.72L15.4 12.381c-.91.895-1.601 1.264-2.072 1.11-.472-.154-.797-.33-.976-.525a1.242 1.242 0 0 0-.877-.342 1.12 1.12 0 0 0-.855.367 1.196 1.196 0 0 0-.365.878c0 .34.122.627.365.854.407.424.846.736 1.317.939.473.203.96.306 1.464.306.601 0 1.22-.15 1.854-.452.634-.3 1.26-.76 1.878-1.378l3.976-3.93c.439-.454.78-.967 1.023-1.536.245-.57.367-1.164.367-1.781 0-.635-.122-1.237-.367-1.807A5.08 5.08 0 0 0 21.11 3.55Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
