import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import Txt, { TxtProps } from '../ui/Txt';
import * as styles from './Card.css';
import { useCard } from './useCard';

export type CardDisabledProps<D extends React.ElementType = 'span'> = TxtProps<D>;

export const CardDisabled = <D extends React.ElementType>({
  children,
  ...props
}: CardDisabledProps<D>) => {
  const { disabled } = useCard();
  const t = useTranslations('entryIndex');

  if (!disabled) return null;

  return (
    <Txt as="span" {...mergePropsClassName(props, styles.disabled)}>
      {children || t('pastEvent')}
    </Txt>
  );
};
