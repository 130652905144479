'use client';

import React from 'react';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'use-intl';
import * as style from './Card.css';
import { useCard } from './useCard';
import Btn, { BtnComponent, BtnProps } from '../ui/Btn';

export type CardCtaProps<D extends React.ElementType = 'button'> = BtnProps<D>;

export const CardCta: BtnComponent = <D extends React.ElementType>({
  children,
  ...props
}: CardCtaProps<D>) => {
  const t = useTranslations('actions');
  const { customText } = useCard();

  return (
    <Btn variant="text" underline="always" {...mergePropsClassName(props, style.cta)}>
      {children || customText || t('readMore')}
    </Btn>
  );
};
