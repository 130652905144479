import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.039 20.572h16.924V9.142H4.039v11.43ZM8.655 7V3.786a.325.325 0 0 0-.026-.139.346.346 0 0 0-.082-.117.378.378 0 0 0-.277-.102H7.5a.4.4 0 0 0-.275.102.346.346 0 0 0-.083.117.325.325 0 0 0-.026.139V7a.322.322 0 0 0 .025.138.343.343 0 0 0 .084.118.378.378 0 0 0 .276.101h.77a.4.4 0 0 0 .276-.1A.33.33 0 0 0 8.655 7Zm9.23 0V3.786a.324.324 0 0 0-.026-.139.344.344 0 0 0-.082-.117.4.4 0 0 0-.276-.101h-.77a.4.4 0 0 0-.276.1.327.327 0 0 0-.108.256v3.216c0 .104.036.189.108.256a.379.379 0 0 0 .277.1h.769a.4.4 0 0 0 .276-.1.346.346 0 0 0 .083-.118.325.325 0 0 0 .026-.138h-.001Zm4.616-.715v14.286c0 .387-.152.721-.457 1.005-.14.135-.308.242-.494.315-.186.073-.386.11-.587.108H4.039c-.416 0-.777-.141-1.081-.424a1.375 1.375 0 0 1-.341-.458 1.288 1.288 0 0 1-.116-.546V6.286c0-.387.152-.722.457-1.004.14-.136.307-.243.493-.316.186-.073.386-.11.588-.109h1.539V3.786c0-.491.188-.911.565-1.261.175-.17.386-.303.62-.393.234-.09.485-.135.738-.132h.77c.528 0 .98.175 1.357.525.377.35.565.77.565 1.26v1.072h4.616V3.786c0-.491.188-.911.565-1.261a1.86 1.86 0 0 1 .62-.393c.233-.09.484-.135.737-.132h.77c.529 0 .982.175 1.358.525.377.35.565.77.565 1.26v1.072h1.539c.416 0 .777.142 1.08.425.306.282.458.617.458 1.004Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
