import { mergePropsClassName } from '@liquorice/utils';
import * as styles from './Shim.css';
import Box, { BoxProps } from '../ui/Box';
import { Sprinkles, sprinkles } from '@/theme/sprinkles.css';

export type ShimProps<D extends React.ElementType = 'div', P = NoProps> = BoxProps<
  D,
  P & {
    InnerProps?: BoxProps;
    ratio?: Sprinkles['paddingTop'];
    relative?: boolean;
  } & styles.ShimVariants
>;

const Shim = <T extends TagName>({
  InnerProps,
  round,
  ratio,
  relative,
  children,
  ...props
}: ShimProps<T>) => {
  children = children && <Box {...mergePropsClassName(InnerProps, styles.inner)}>{children}</Box>;
  return (
    <Box
      {...mergePropsClassName(
        props,
        [styles.root({ round, relative }), sprinkles({ paddingTop: ratio })].join(' ')
      )}>
      {children}
    </Box>
  );
};

export default Shim;
