import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.83 18.671c0 1.042-.347 1.864-1.038 2.468-.69.604-1.61.905-2.755.905H6.621c-1.145 0-2.064-.301-2.754-.905-.692-.604-1.038-1.425-1.038-2.468 0-.46.016-.908.05-1.347.033-.438.1-.913.2-1.419.098-.509.224-.979.376-1.412.15-.435.354-.858.61-1.27.238-.39.534-.746.88-1.055a3.778 3.778 0 0 1 1.215-.697 4.613 4.613 0 0 1 1.584-.26c.085 0 .284.093.597.28.312.187.664.394 1.058.624.393.23.904.439 1.533.625.63.188 1.263.28 1.897.28.635 0 1.267-.092 1.896-.28.631-.186 1.142-.395 1.534-.625.394-.23.746-.437 1.058-.625.314-.186.512-.28.598-.28.577 0 1.106.088 1.584.261.477.174.882.405 1.213.697.333.29.627.642.881 1.055.256.412.46.835.611 1.268.152.435.277.905.377 1.413.099.509.166.981.199 1.42.033.437.05.887.05 1.347ZM18.282 7.044c0 1.38-.532 2.559-1.598 3.535-1.065.977-2.349 1.465-3.856 1.465-1.506 0-2.79-.488-3.856-1.465-1.066-.976-1.598-2.155-1.598-3.535 0-1.38.533-2.559 1.598-3.535 1.066-.978 2.35-1.465 3.856-1.465 1.507 0 2.791.487 3.856 1.465 1.067.976 1.598 2.155 1.598 3.535Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
