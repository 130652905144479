import { truncate } from 'lodash';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';

export type CardDescriptionProps<D extends React.ElementType = 'span'> = TxtProps<
  D,
  {
    disableDefaultColor?: boolean;
    enableTruncate?: boolean;
  }
>;

export const CardDescription = <D extends React.ElementType>({
  children,
  enableTruncate = false,
  ...props
}: CardDescriptionProps<D>) => {
  const { description, disabled } = useCard();

  let content = children || description;
  const truncatedContent = truncate(content, { length: 100 });

  if (enableTruncate) content = truncatedContent;

  return (
    content && (
      <Txt
        as="span"
        variant="body"
        html
        {...mergePropsClassName(props, style.description({ disabled }))}>
        {content}
      </Txt>
    )
  );
};
