import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.736 18.904c.306.36.457.786.457 1.277 0 .493-.152.92-.457 1.28-.305.36-.665.54-1.082.54-.416 0-.776-.18-1.08-.54a1.781 1.781 0 0 1-.341-.584 2.03 2.03 0 0 1-.117-.696c0-.49.152-.917.457-1.277a1.5 1.5 0 0 1 .494-.403c.186-.093.386-.14.587-.137.417 0 .778.18 1.082.54Zm10.769 0c.305.36.457.786.457 1.277 0 .493-.152.92-.456 1.28-.305.36-.665.54-1.082.54-.417 0-.777-.18-1.082-.54a1.78 1.78 0 0 1-.34-.584 2.03 2.03 0 0 1-.117-.696c0-.49.152-.917.457-1.277.305-.36.665-.54 1.082-.54.417 0 .776.18 1.08.54ZM22.5 4.728V12a.976.976 0 0 1-.198.604.732.732 0 0 1-.488.305L9.27 14.643c.103.567.156.9.156.993 0 .152-.096.455-.289.91h11.058c.209 0 .389.09.541.27a.89.89 0 0 1 .17.291c.04.11.059.229.058.348 0 .12-.019.238-.058.348a.89.89 0 0 1-.17.29.75.75 0 0 1-.247.202.642.642 0 0 1-.294.069H7.884c-.1 0-.2-.023-.293-.069a.753.753 0 0 1-.246-.201.89.89 0 0 1-.17-.291c-.04-.11-.06-.229-.059-.348 0-.105.032-.254.096-.447.057-.175.121-.346.193-.513.063-.146.15-.335.258-.567.108-.233.17-.372.186-.42L5.723 3.819H3.269a.64.64 0 0 1-.294-.068.752.752 0 0 1-.247-.201.891.891 0 0 1-.17-.292 1.015 1.015 0 0 1-.057-.348c0-.246.075-.459.228-.639a.752.752 0 0 1 .247-.2.64.64 0 0 1 .293-.07h3.077a.637.637 0 0 1 .318.079c.1.054.188.134.259.234.056.085.108.2.156.347.048.148.08.27.096.37.042.262.082.525.12.788H21.73c.209 0 .39.09.541.27a.89.89 0 0 1 .17.291c.04.11.06.23.06.349Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
