import { SeomaticEnvironment } from '__generated__/graphql';

export const SITE = process.env.NEXT_PUBLIC_SITE || 'default';

/** App in 'development' environment */
export const IS_DEV = process.env.NODE_ENV === 'development';

export const DISABLE_CACHE = IS_DEV && process.env.NEXT_PUBLIC_DISABLE_CACHE === 'true';

export const TIMEZONE = process.env.NEXT_PUBLIC_TIMEZONE ?? 'Australia/Melbourne';
export const LOCALE = process.env.NEXT_PUBLIC_LOCALE ?? 'en-AU';
export const I18N_DEFAULT_LOCALE = 'en';

export const REVALIDATION_TIMEOUT = 60;

export const DRAFT_MODE_ROUTE = '/api/draft/';
export const QUERY_FORWARD_ROUTE = '/api/pass/';

export const SEO_ENVIRONMENT =
  (process.env.NEXT_PUBLIC_SEO_ENVIRONMENT as SeomaticEnvironment) ?? null;

export const SEPARATOR = `•`;

export enum IMG_RATIO {
  SQUARE = 1,
  PORTRAIT = 1.42, // 3:2
  LANDSCAPE = 496 / 744, // 0.715,
  LANDSCAPE_TALL = 0.8, // ~4:3
  LANDSCAPE_WIDE = 0.54, // 16:9
  VIDEO = 0.5625, // 16:9
}

export type ImgRatios = typeof IMG_RATIO;
export type ImgRatio = keyof ImgRatios;

// Entry type URI settings

export const ENTRY_TYPE_URI_SETTINGS = {
  eventIndex: 'events',
  articleIndex: 'news',
} as const;

export const SEARCHABLE_ENTRY_TYPES = ['article', 'event', 'page'];

export const WAGTAIL_URL = process.env.WAGTAIL_URL;
export const LOCAL_WAGTAIL_URL = process.env.LOCAL_WAGTAIL_URL || process.env.WAGTAIL_URL;
export const SITE_URL = process.env.SITE_URL;
export const WAGTAIL_PAGE_ROUTE = '/api/v2/pages/';
export const WAGTAIL_ROUTE = '/api/v2/';

export const NGROK_URL = process.env.NGROK_URL;
