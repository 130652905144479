import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.217 8.848c.189.103.283.275.283.515v12.01c0 .447-.246.67-.737.67h-5.439c-.453 0-.68-.223-.68-.67v-7.37H9.356v7.37c0 .447-.227.67-.68.67h-5.44c-.49 0-.736-.223-.736-.67V9.364c0-.24.094-.413.283-.516l9.122-6.65c.302-.206.604-.206.907 0l9.405 6.65Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
