import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63V3WrDIBTA8fs%2BheDNCu3QGKO1t9uDGI39rlvWdl%2Fs3YcNjJMyDgc2vPvX%2FjjR0rg%2B5xP7nDA2nyd92Oa9cIyLRigbl6DKX2vlGFdemRBgVY5xa6xOCdbaMR6aUI%2BrdownnW72No7xLgphJaym7DWhiwZWW2qyyStYF9faiW6015cak0oa1va6Ny2ShzWUqrxQNazRMf4odaUeYO3K6Qgt9Wje5Bg3uixYV45xH8qCde0Yr21ZsG6GyWDalqOphBBiOfma3A%2FxMLrBnWMX39%2F9XOgUfn8%2F%2FtBMAXPEGIkxG8hkjFEYIyHzhDEVeZpnjKnJ0%2FQYo8nMC8Y05Ic6YYwhT3PGmAWZuWCMJTOv%2F8O8YUxLZt4xJpCZD4yJ5AuXAnM6uiMxJ9GdCnNWmLMeOQpz1vR5aszZkK9LaszZ0udp%2FjhPm%2BPwCwx5n%2FubY5mV183wv976sFv1%2BXyM8192bmbXF0gRvwFg2rC56gcAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62WzY6bMBSF93kKLxM1WP43ZlazmGVXfYCKgJO4JZABmvmp%2Bu5VCIQxXEujSbbX3I97zvFFJHVVtejvAqEo%2BkmdZhtHSYJOab2Mol37eLC1y9Kn19aWuc1X6%2BnJjzYt87Q%2BnzzWLi3WqEnLJmps7bYPHpXOqJ%2FvZTf08lnv96qsblEiEpQV6eG4ZJgyWdvDGmVpkS0jikVMVFdC35DGkrLTy2qNRP%2FcyufIgUOxmnCINgNGYsVFh%2BEwRo2YjxSCpeaG6x5DsSBxhxneNsHoEaM%2FYChmxnA6TEOw4abDsDkiDiForK62UEz1RQ%2FDBJzEjBjqK1JaQKNQfJE54aQhYzSsiGIAshkgHHuSlNE0HszlWEvVRw0wsnDQ8uzugFHnyPqoQUV5ctXqH9h7SN0GfY%2BNUSNEqAECpbcbKMS7BARLJgAIwTGkdB%2BiBG4jnSPcdU09xxWR0IrO%2B3%2BNdviZMSKA5ZwDfgf9FCKGNxNworhHtIe7RFuOocR%2BKoIFFhP8YlVBjhLgNHPEEXYlotgQAW7UnPF8ZXjXVARshZTUtytp7rIv7UhRfsAcDLh%2F2YTyJ0GCEL92SpCc1l4SpKa11wTpae0N6H1P0KRy%2FuEgD4t%2Fi02Vv3X%2FItuqbKPGvduv2lu40kZ763b79uvrU9i2tXXUHNPMlbvLjP8BBCJUtjAJAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMjRweDsKICAtLV8xYWNlYjRxMzogMjRweDsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuMTI1cmVtOwogIC0ta2RtdnFpNzogMC4yNXJlbTsKICAtLWtkbXZxaTg6IDAuMzc1cmVtOwogIC0ta2RtdnFpOTogMC41cmVtOwogIC0ta2RtdnFpYTogMC43NXJlbTsKICAtLWtkbXZxaWI6IDFyZW07CiAgLS1rZG12cWljOiAxLjI1cmVtOwogIC0ta2RtdnFpZDogMS41cmVtOwogIC0ta2RtdnFpZTogMnJlbTsKICAtLWtkbXZxaWY6IDIuMzc1cmVtOwogIC0ta2RtdnFpZzogY2xhbXAoMi4zNzVyZW0sIGNhbGMoMS4yNDgyNXJlbSArIDIuMzQ3dncpLCAzcmVtKTsKICAtLWtkbXZxaWg6IGNsYW1wKDNyZW0sIGNhbGMoMi4wOTg1NjI1cmVtICsgMS44Nzh2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlpOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS42OTcxODc1cmVtICsgMy43NTZ2dyksIDQuNXJlbSk7CiAgLS1rZG12cWlqOiBjbGFtcCgzLjVyZW0sIGNhbGMoMC43OTU3NXJlbSArIDUuNjM0dncpLCA1cmVtKTsKICAtLWtkbXZxaWs6IGNsYW1wKDVyZW0sIGNhbGMoMC40OTI5Mzc1cmVtICsgOS4zOTB2dyksIDcuNXJlbSk7Cn0%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U3W6jMBCF7%2FsU57KVQuThN8y%2BQN%2Bglyvz08RNgqkhLd3VvnsFSlrGtM1KIIbPZ84MxjY7a3v8vQGCwLqh3aeKEZBS6teMESPIJQoZJEnESCSJGakkyZQlUMqgSKKMId43jCRL22HOckaWbiTTjDwlyQoGUR5LWDIojr3sihFKUjM6ezDVnD16vW0Zap24%2BjiHuxFuMh8bBq0p9PETI1xY7Bkv2t0GwW8yWVgY6u7mwwcGeRnHsejCvBlrRotW7DK%2F9Ss%2Bi4rPyww3VlxYd77PQfj049cuJ%2BE09rmcsheGaofpTtsBblvoW7XC%2BVpTJLxfP9WkrsuHmfw%2F3N%2F4o4%2FwkuenhMnd6sM0%2FkZEifD9M%2F24Tmw4xZNOQvpCGI4skiwaWSxZPK1TyRJGocv91tlTUwWlPVi3wvlRWFfV7gKnXlDrrg5ME9hTL3zSr3x%2BTskYttWl6d%2BuCDeM3umme7TueEWaM3a12e76FY56uL%2BEprmEr6bqd9PgwzkyzTn62Vl7m54KhnekUMnwDhSqeFpXgtWMSI3s3zvXISe%2FewUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82Y2W7bMBBF3%2F0V81IgBSRDMr1FBvorBS1RIh0uDkV5SZF%2FLxI3EZkGHBte4FfrcO61yBleaOj2jtfZGP4MAEojjS1gQ%2B1Dmh4eZAkIzZkV7udiAFAb7dItEw13ITfyudfB8PDr5L3sZ62PJfVErYzMfHIaI3OfnMXIkU%2FOYyTxyccYOfZJGiMnPrmMkVOfLGPkzCerGDn3SRYjH32yjpHUJ5sYufRJHiNLnxQxsvLJVYxkPvkUI2uflDGy8UkVI7lP6hgpfNLEyJVPrt%2FJb5rvdy5mo6XI93131lQJuf8KZD3Qihf29bF4fyyFZin%2FViBogudr21lhdoJOs9e284TZCdq5vbYdidkJZoa7th2F2QkGU3dtOxqzE0y%2FTW%2Fne7U8ruYwtWCKbM9UazG1YLrtzlSzmFowdfdnqj1jasFt8IKpIadkjakF91meYae2w04t8u8N5ie4ifMR5ic%2Fxk9fj1ykK%2Ft6Y6zeDqsX5LP8ECQVtY3Q6dI4Z1QB2XDKlE8dQqRjO5c6S3VbG6sK6NZrZkvaMh%2BdHbOhPT7H8E2AP2L4NsDpMW%2FrEyfAnZIJLE11VM95C1tnjW5OVKtF01kGv0Cow9KtqBwvIM%2ByH2%2Bb%2BHFeaefMwtslacqnlOmqgDzYJgLLEx10MgFzyGprWlVCN6lktSsgH85LHqBSHHNSCPD8viIVAY729G1TFQF%2BmalwsWBFgKNz5bbZigCf3Fe8IsCn95WwCDihzw0Hp8UsAq2iUt40bBFQrBKdumnmIiCpbdhNkxeBHfpmLxq%2ByJeb7h4C2NsFkgAfJcBJAnycAJ8kH62HXj70%2F69t%2FeerfwGmYqWx1AmjC%2Bh0xeybtcXg9S9UGdHusRMAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'tythf04',variantClassNames:{color:{primaryAlt:'tythf05',primary:'tythf06',primaryTintDark:'tythf07',primaryTint:'tythf08',primaryTintLight:'tythf09',primaryTintLighter:'tythf0a',secondaryAlt:'tythf0b',secondary:'tythf0c',secondaryTintDark:'tythf0d',secondaryTint:'tythf0e',secondaryTintLight:'tythf0f',secondaryTintLighter:'tythf0g',orange:'tythf0h',notifyRed:'tythf0i',notifyGreen:'tythf0j',neutralDark:'tythf0k',neutralLight:'tythf0l',black:'tythf0m',white:'tythf0n',error:'tythf0o'},variant:{h1:'tythf0p',h2:'tythf0q',h3:'tythf0r',h4:'tythf0s',h5:'tythf0t',h6:'tythf0u',tiny:'tythf0v',small:'tythf0w',medium:'tythf0x',large:'tythf0y',xl:'tythf0z',body:'tythf010',inherit:'tythf011'},bodyFont:{true:'tythf012'},headingFont:{true:'tythf013'},monoFont:{true:'tythf014'},autoMargin:{true:'tythf015'},uppercase:{true:'tythf016'},weight:{regular:'tythf017',medium:'tythf018',semibold:'tythf019',bold:'tythf01a'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','variant','bodyFont','headingFont','monoFont','autoMargin','uppercase','weight']};
export var txtVars = {color:'var(--tythf00)',display:'var(--tythf01)',fontWeight:'var(--tythf02)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1i)',lineHeight:'var(--_1i72bi14)'},h2:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1j)',lineHeight:'var(--_1i72bi15)'},h3:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1k)',lineHeight:'var(--_1i72bi16)'},h4:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1l)',lineHeight:'var(--_1i72bi17)'},h5:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1m)',lineHeight:'var(--_1i72bi18)'},h6:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1n)',lineHeight:'var(--_1i72bi19)'},tiny:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1t)',lineHeight:'var(--_1i72bi1g)'},small:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1s)',lineHeight:'var(--_1i72bi1f)'},medium:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1r)',lineHeight:'var(--_1i72bi1e)'},large:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1q)',lineHeight:'var(--_1i72bi1d)'},xl:{fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1p)',lineHeight:'var(--_1i72bi1b)'},body:{fontWeight:'var(--_1i72bi1u)',fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1o)',lineHeight:'var(--_1i72bi1a)'},inherit:{}};
export var withHtml = 'tythf03';